'use client';

import { FC, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { AuthProvider } from '@prisma/client';
import dayjs from 'dayjs';
import { getSession, signIn } from 'next-auth/react';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter, useSearchParams } from 'next/navigation';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { sendServerSidePosthogEvent } from '@/app/actions/sendServerSidePosthogEvent';
import Button from '@/components/button.component';
import GoogleIcon from '@/components/icons/google-icon.component';
import Input from '@/components/input.component';
import SuccessToast from '@/components/toasts/success-toast.component';
import WarningToast from '@/components/toasts/warning-toast.component';
import { User } from '@/types';

import Logo from '@public/assets/images/logo.png';

export const loginSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .email('This field should be a valid email address.')
    .required('This field is required!'),
  password: yup.string().required('This field required'),
});

type LoginFormData = {
  email: string;
  password: string;
};

const LoginForm: FC = () => {
  const router = useRouter();
  const [loading, setLoading] = useState(false);

  const searchParams = useSearchParams();
  const callbackUrl = searchParams.get('callbackUrl') || '/';

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginSchema),
    reValidateMode: 'onChange',
  });

  const onSubmit = handleSubmit(async (data: LoginFormData) => {
    setLoading(true);

    try {
      const res = await signIn('credentials', {
        redirect: false,
        email: data.email,
        password: data.password,
        callbackUrl,
      });

      setLoading(false);

      if (!res?.error) {
        toast(
          <SuccessToast
            title="Success"
            detail="You have Successfully logged in"
          />,
        );
        const session = await getSession();
        const user: User = session?.user as User;

        await sendServerSidePosthogEvent({
          distinctId: user.id,
          workspaceId: user.workspace.id,
          event: 'login',
          properties: {
            email: data.email,
            provider: AuthProvider.CREDENTIAL,
            workspaceId: user.workspace.id,
            loginDate: dayjs().toISOString(),
          },
        });

        router.push(callbackUrl);
      } else {
        toast(<WarningToast title="Fail" detail="invalid email or password" />);
      }
    } catch (error: any) {
      setLoading(false);
      toast(<WarningToast title="Fail" detail={error.message} />);
    }
  });

  const redirectSearchParams = new URLSearchParams({ callbackUrl });

  const loginWithGoogle = async () => {
    const searchParams = new URLSearchParams(window.location.search);
    const callbackUrl = searchParams.get('callbackUrl') || '/';

    await signIn('google', {
      callbackUrl: callbackUrl,
    });
  };

  return (
    <>
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <Image
          className="mx-auto"
          width={48}
          height={48}
          src={Logo.src}
          alt="LiveChatAi.com"
        />
        <h2 className="mt-5 text-center text-3xl font-extrabold leading-9 text-gray-900">
          Sign in to your account
        </h2>
        <p className="mt-2 text-center text-sm font-normal leading-5 text-gray-600">
          Or{' '}
          <Link
            href={
              redirectSearchParams
                ? `/register?${redirectSearchParams}`
                : '/register'
            }
            className="text-main font-medium hover:opacity-90"
          >
            get started for free
          </Link>
        </p>
      </div>

      <div className="mt-[15px] rounded-lg bg-white shadow-sm sm:mx-auto sm:w-full sm:max-w-md">
        <form onSubmit={onSubmit} className="px-10 py-8">
          <Input
            data-testid="email"
            className="mb-6"
            label="Email address"
            {...register('email')}
            error={errors.email}
          />
          <div className="relative">
            <Input
              data-testid="password"
              className="mb-6"
              label="Password"
              type="password"
              {...register('password')}
              error={errors.password}
            />
            <Link
              href="/forgot-password"
              className="text-main absolute right-0 top-0 text-xs font-medium leading-6 hover:opacity-90"
            >
              Forgot password?
            </Link>
          </div>

          <Button
            data-testid="login"
            type="submit"
            variant="primary"
            isLoading={loading}
          >
            Login
          </Button>

          <hr className="mb-5 border-0 border-b border-gray-300 pb-5 pt-0" />

          <Button
            type="button"
            variant="light"
            className="mt-4"
            handleClick={loginWithGoogle}
          >
            <GoogleIcon className="mr-2" width="18" height="18" />
            Login with Google
          </Button>
        </form>
      </div>
    </>
  );
};

export default LoginForm;
